import { library, dom, config } from '@fortawesome/fontawesome-svg-core';
import { faSearch as faSearchLight, faSync as FaSyncLight, faEye, faGlobe, faTimes as faTimesLight, faFileWord, faFilePowerpoint, faFileImage, faFileSpreadsheet, faFileArchive, faFilePdf, faFile, faPlayCircle, faArrowUp, faEdit, faSpinner, faQuoteLeft, faQuoteRight, faUser as faUserLight } from '@fortawesome/pro-light-svg-icons';
import { faChevronRight as faChevronRightRegular, faChevronLeft as faChevronLeftRegular, faChevronUp as faChevronUpSolid, faSearch as faSearchRegular, faTimes as faTimesRegular } from '@fortawesome/pro-regular-svg-icons';
import { faSearch as faSearchSolid, faLink, faDownload, faCircle, faSpinner as faSpinnerSolid, faTrophy } from '@fortawesome/pro-solid-svg-icons';
import { faFacebookF, faFacebookSquare, faTwitter, faTwitterSquare, faLinkedinIn, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';

config.searchPseudoElements = true;
config.keepOriginalSource = false;

library.add(
	faSearchLight, 
	faSearchSolid,
	faSearchRegular,
	faFilePdf,
	faFileWord, 
	faFilePowerpoint, 
	faFileImage, 
	faFileSpreadsheet, 
	faFileArchive,
	faFile,
	faEye,
	faChevronRightRegular,
	faChevronLeftRegular,
	faLink,
	faDownload,
	faFacebookF,
	faFacebookSquare,
	faTwitter,
	faTwitterSquare,
	faLinkedinIn,
	faLinkedin,
	faGlobe,
	faTimesLight,
	faTimesRegular,
	faPlayCircle,
	faArrowUp,
	faCircle,
	faInstagram,
	faEdit,
	faSpinner,
	faQuoteLeft, 
	faQuoteRight,
	faChevronUpSolid,
	faSpinnerSolid,
	faUserLight,
	FaSyncLight,
	faTrophy
);
dom.i2svg();
dom.watch();